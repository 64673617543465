.header-image {
  background-image: url('../images/hero_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.button-bg {
  background: linear-gradient(97.24deg, #ff0009 -5.21%, #0036f3 102.43%);
  animation: rotate 1.6s linear both infinite;
}

@keyframes rotate {
  100% {
    filter: hue-rotate(360deg);
  }
}

.roadmap-bg {
  background-image: url('../images/roadmap.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 80rem;
}

@media only screen and (min-width: 1024px) {
  .roadmap-bg {
    height: 100rem;
  }
}

@media only screen and (min-width: 570px) and (max-width: 1023px) {
  .roadmap-bg {
    height: 60rem;
  }
}

@media only screen and (min-width: 425px) and (max-width: 569px) {
  .roadmap-bg {
    height: 55rem;
  }
}

@media only screen and (max-width: 424px) {
  .roadmap-bg {
    height: 55rem;
  }
}